import React from "react";
import { Row } from "reactstrap";
import LiteratureItem from "./literatureItem";
import { GetResources } from "../../services/library";
import NoResults from "../atoms/noResults";
import Loading from "../atoms/loading";

export default class Videos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      loaded: false,
    };
    this.refresh();
  }

  refresh = () => {
    this.setState({ articles: [], loaded: false });
    GetResources(2, null).then((result) => {
      if (!result.err) {
        this.setState({
          articles: result.data,
          loaded: true,
        });
      }
    });
  };

  render() {
    const allItems = this.state.articles.map((item, key) => (
      <LiteratureItem key={key} item={item}></LiteratureItem>
    ));

    return (
      <>
        <Row>{allItems}</Row>
        {!this.state.loaded && (
          <Row>
            <Loading size="lg"></Loading>
          </Row>
        )}
        {this.state.loaded && this.state.articles.length === 0 && (
          <NoResults></NoResults>
        )}
      </>
    );
  }
}
