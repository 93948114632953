import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { Trans } from "react-i18next";

const StyledContainer = styled.div`
    width: 100%;
    font-size: 35px;
    padding: 40px 20px;
    text-align: center;
    @media screen and (max-width: 550px) {
        font-size: 20px;
    }
`;
const NoResults = ({ ...props }) => {
  return (
    <Row>
      <Col>
        <StyledContainer>
          <FontAwesomeIcon icon={faCircleXmark}></FontAwesomeIcon> <br />
          <Trans>No Results Found</Trans>
        </StyledContainer>
      </Col>
    </Row>
  );
};

export default NoResults;
