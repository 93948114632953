import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/atoms/seo";
import { LIBRARY_COLOR } from "../../theme/default";
import H1 from "../../components/atoms/h1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { graphql } from "gatsby";
import LibraryTabs from "../../components/organisms/libraryTabs";
import Hr from "../../components/atoms/hr";
import { Trans, withTranslation } from "react-i18next";
import Videos from "../../components/molecules/videos";


class Video extends React.Component {
  render() {
    return (
      <Layout color={LIBRARY_COLOR}>
        <Seo title={this.props.t("Video Library")} keywords={[`PerkinElmer`]} />
        <LibraryTabs active={2}></LibraryTabs>
        <Hr></Hr>
        <H1>
          <FontAwesomeIcon icon={faVideo}></FontAwesomeIcon>&nbsp;
          <Trans>Video Library</Trans>
        </H1>
        <p>
          <Trans>These videos were created to provide beneficial tips and display techniques to users of GC capillary columns.</Trans>
        </p>
        <Videos></Videos>
      </Layout>
    );
  }
}
export default withTranslation()(Video);
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "library"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
