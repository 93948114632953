import React from "react";
import styled from "styled-components";
import { Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../atoms/button";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import LinkExternal from "../atoms/LinkExternal";
import doc from "../../images/library/pdf.png"
import Center from "../atoms/center";
import { Trans } from "react-i18next";

const StyledContainer = styled(Col)`
  padding: 0;
  text-align: center;
  margin-bottom: 30px;
  &:hover .menu {
    display: block;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
const StyledExternalLink = styled(LinkExternal)`
  display: block;
  color: #444 !important;
  padding: 10px;
  text-decoration: none;
`;

const Title = styled.div`
min-height: 55px;
@media screen and (max-width: 768px) {
    font-size: 12px;
  }
`

const StyledIMG = styled.img`
  margin: auto;
  max-width: 190px;
  display: block;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    width: 104px;
    margin-bottom: 11px;
  }
`;
const LiteratureItem = ({ ...props }) => {
  return (
    <StyledContainer
      xs="6"
      sm="6"
      md="6"
      lg="3"
    >
      <Center>
          <StyledExternalLink href={props.item.Link}>
            <Title><Trans>{props.item.Title}</Trans></Title>
            <StyledIMG
            src={props.item.Image ? `data:image/png;base64,${props.item.Image}` : doc}
              alt="doc"
              placeholder="none"
            />
            <Button>
            <FontAwesomeIcon icon={faChevronCircleRight}></FontAwesomeIcon>
            &nbsp;&nbsp;<Trans>View</Trans>
              
            </Button>
          </StyledExternalLink>
      </Center>
    </StyledContainer>
  );
};

export default LiteratureItem;