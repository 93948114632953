import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";

const StyledFontAwesomeIconSmall = styled(FontAwesomeIcon)`
  font-size: inherit;
  color: #9c9c9c;
`;

const StyledFontAwesomeIconMedium = styled(FontAwesomeIcon)`
  font-size: 45px !important;
  color: #9c9c9c;
  margin-bottom: 20px;
  margin-top: 20px;
`;
const StyledFontAwesomeIconLarge = styled(FontAwesomeIcon)`
  font-size: 80px !important;
  color: #9c9c9c;
  margin-bottom: 50px;
  margin-top: 50px;
`;
const StyledContainer = styled.div`
  width: 96%;
  text-align: center;
  z-index: 999;
`;
const UploadingText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 32px;
  color: #878282;
  font-style: italic;
  width: 100%;
  text-align: center;
`;
const Loading = ({ ...props }) => {
  return (
    <StyledContainer
      style={{
        position: props.abs ? "absolute" : "relative",
        top: props.abs ? "10px" : "",
        width: props.abs ? "97.4%" : "100%",
      }}
    >
      {props.message && <UploadingText>{props.message}</UploadingText>}
      {props.size === "lg" && (
        <StyledFontAwesomeIconLarge
          className="fa-spin fa-pulse fa-4x"
          icon={faSpinner}
        ></StyledFontAwesomeIconLarge>
      )}
      {props.size === "md" && (
        <StyledFontAwesomeIconMedium
          className="fa-spin fa-pulse fa-2x"
          icon={faSpinner}
        ></StyledFontAwesomeIconMedium>
      )}
      {props.size !== "lg" && props.size !== "md" && (
        <StyledFontAwesomeIconSmall
          className="fa-spin fa-pulse fa-sx"
          icon={faSpinner}
        ></StyledFontAwesomeIconSmall>
      )}
    </StyledContainer>
  );
};

export default Loading;
